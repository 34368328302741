<template>
  <div class="zuoyes">
    <div class="zuoye_list">
      <div class="close">
        <i class="el-icon-circle-close" @click="closeAction"></i>
      </div>
      <div
        :class="[
          'stu',
          {
            you: item.jibie >= 90,
            liang: item.jibie >= 60 && item.jibie < 90,
            cha: item.jibie < 60,
          },
        ]"
        v-for="item in stus"
        :key="item.id"
      >
        <h3 @click="skipStuInfo(item.stu_name)">{{ item.stu_name }}</h3>
        <el-input
          placeholder="进度"
          v-model="item.jibie"
          clearable
          size="mini"
          @change="changeAction(item)"
        ></el-input>
      </div>
    </div>
    <div class="demo-input-suffix">
      <h3>讲师评论</h3>
      <div class="edite">
        <el-row>
          <el-col :span="24">
            <el-select v-model="index" clearable placeholder="学生姓名">
              <el-option
                v-for="item, index in stus"
                :key="item.id"
                :label="item.stu_name"
                :value="index"
              >
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="10" style="padding: 10px 0">
            <el-input
              type="textarea"
              :rows="2"
              placeholder="评论内容"
              prefix-icon="el-icon-search"
              v-model="des"
            >
            </el-input>
          </el-col>
        </el-row>
        <el-row>
          <el-button type="primary" round @click="saveDes">新增</el-button>
        </el-row>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "StuList",
  data() {
    return {
      stus: [],
      row: {},
      check: true,
      des: "",
      index:""
    };
  },
  async created() {
    let id = this.$route.params.id.split("-")[1];
    //获取单条作业
    this.$datas.meta = { id };
    let zyR = await this.$datas.getZuoYeOne;
    this.row = zyR.data;
    if (zyR.status == 200) {
      //从服务器端拉取学生
      this.$datas.meta = { className: zyR.data.bj.className };
      let res1 = await this.$datas.getStus;

      let datas = res1.data.msg.data;
      if (zyR.data.zyj.length <= 0) {
        for (let i in datas) {
          let item = datas[i];
          this.stus.push({
            stu_name: item.name,
            jibie: 0.0,
            check_id: id,
            tel: item.phone,
          });
          this.$datas.meta = this.stus[i];
          let res1 = await this.$datas.addZuoYeJiLu;
          this.stus.splice(i, 1, { ...this.stus[i], id: res1.data.id });
        }
      } else {
        this.stus = zyR.data.zyj;
        for (let i in this.stus) {
          if (this.stus[i].kind == 0) {
            this.stus.splice(i, 1);
          }
        }
      }
    }
    // for(let i in this.stus){
    //     for(let j in datas){
    //         if(this.stus[i].stu_name == datas[j].name){
    //             this.$datas.meta = {...this.stus[i], tel:datas[j].phone};
    //             let res3 = await this.$datas.updateZuoYeJiLu;
    //             console.log(res3);
    //         }
    //     }
    // }
  },
  methods: {
    closeAction() {
      this.$router.go(-1);
    },
    async changeAction(item) {
      this.$datas.meta = item;
      //数据更新
      await this.$datas.updateZuoYeJiLu;
      this.check = false;
    },
    async skipStuInfo(stu_name) {
      this.$router.push({
        name: "JieDuanZuoYe",
        query: {
          row: { stu_name, ...this.row, className: this.row.bj.className },
        },
      });
    },
    // 更新评论
    async saveDes(){
      this.check = true;
      let date = new Date();
      let year = date.getFullYear();
      let month = (date.getMonth()+1) < 10 ? "0"+(date.getMonth()+1):(date.getMonth()+1);
      let day = date.getDate() < 10 ? "0"+date.getDate():date.getDate();
      const stu = this.stus[this.index];
      this.$datas.meta = {id:stu.id, stu_name:stu.stu_name, des:this.des, des_date:`${year}-${month}-${day}`};
      //数据更新
      await this.$datas.updateZuoYeJiLu;
      this.$message("评论已添加!");
      this.check = false;
    }
  },
};
</script>
<style lang="less" scoped>
.zuoye_list {
  padding: 20px;
  box-sizing: border-box;
  overflow: hidden;
  .close {
    padding: 10px 20px;
    text-align: right;
    i {
      font-size: 25px;
      cursor: pointer;
      color: #555;
      &:hover {
        color: #aaff;
      }
    }
  }
  .stu {
    width: 120px;
    padding: 5px 10px;
    border: 1px solid #999;
    border-radius: 5px;
    float: left;
    margin: 10px;
    h3 {
      margin-bottom: 5px;
      cursor: pointer;
    }
  }
  .liang {
    background-color: #409eff;
    color: white;
    border: 1px solid #409eff;
  }
  .cha {
    background-color: #f56c6c;
    color: white;
    border: 1px solid #f56c6c;
  }
}
.edite {
  padding: 15px 20px;
}
</style>
